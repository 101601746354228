<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Programaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Programaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-8 text-center">
                    <div
                      v-if="loading"
                      class="spinner-border text-dark"
                      role="status"
                    >
                      <span class="sr-only">Guardando...</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <router-link to="/Hidrocarburos/ProgramacionesCreate">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="newItem = true"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.programacion.create'
                            )
                          "
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        N°
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="id"
                          @keyup="filtrarTabla()"
                        />
                      </th>
                      <th>
                        Nominación
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="nominacion"
                          label="nombre"
                          :options="nominacionesOption"
                          placeholder="Nominacion"
                          @input="filtrarTabla()"
                        ></v-select>
                      </th>
                      <th>
                        Fecha inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="fecha_inicial"
                          @keyup="filtrarTabla()"
                        />
                      </th>
                      <th>
                        Fecha final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="fecha_final"
                          @keyup="filtrarTabla()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          v-model="estado"
                          @change="filtrarTabla()"
                          class="form-control form-control-sm"
                        >
                          <option
                            v-for="r in listas"
                            v-bind:key="r.id"
                            v-bind:value="r.numeracion"
                          >
                            {{ r.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Fecha creación</th>
                      <th align="center">Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in filteredRows"
                      :key="`id-${index}`"
                    >
                      <td v-html="highlightMatches(row.id)"></td>
                      <td v-html="highlightMatches(row.nominacion.nombre)"></td>
                      <td v-html="highlightMatches(row.fecha_inicial)"></td>
                      <td v-html="highlightMatches(row.fecha_final)"></td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            row.estado == 1 ? 'badge-warning' : 'badge-success'
                          "
                        >
                          {{ row.nEstado }}
                        </span>
                      </td>
                      <td
                        v-html="
                          highlightMatches(row.created_at.substring(0, 10))
                        "
                      ></td>
                      <!--td v-html="highlightMatches([...row.employees].sort().join(', '))"></td-->
                      <td class="text-right">
                        <div class="row">
                          <div class="col-6 text-right">
                            <router-link
                              :to="
                                '/Hidrocarburos/ProgramacionesEdit/' + row.id
                              "
                            >
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                v-if="
                                  $store.getters.can(
                                    'hidrocarburos.programacion.show'
                                  )
                                "
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </router-link>
                          </div>
                          <div class="col-6 text-left" v-if="row.estado != 2">
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-if="
                                $store.getters.can(
                                  'hidrocarburos.programacion.delete'
                                )
                              "
                              @click="delProgramacion(row.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="programaciones.total">
                  <p>
                    Mostrando del <b>{{ programaciones.from }}</b> al
                    <b>{{ programaciones.to }}</b> de un total:
                    <b>{{ programaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="programaciones"
                  @pagination-change-page="filtrarTabla"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import serverConfig from "./../../../../services/config";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";

export default {
  name: "ProgramacionIndex",
  components: {
    pagination,
    vSelect,
  },
  data() {
    return {
      id: null,
      programacionesTabla: [],
      nominacionesOption: [],
      nominacion: [],
      fecha_inicial: null,
      fecha_final: null,
      estado: null,
      filter: "",
      filtros: {},
    };
  },
  beforeMount() {
    this.filtros = {
      id: null,
      nominacion_id: null,
      fecha_inicial: null,
      fecha_final: null,
      estado: null,
    };
    var data = [];
    data[0] = 1;
    data[1] = this.filtros;
    this.actGetProgramaciones(data).then(() => {
      this.programacionesTabla = this.programaciones.data;
    });
    this.actGetListas(28);
    this.actGetNominacionesList().then(() => {
      this.nominacionesList.forEach((row) => {
        if (row.estado == 2) {
          this.nominacionesOption.push({
            id: row.id,
            nombre: row.nombre,
            tipo_producto: row.tipo_producto,
          });
        }
      });
    });
  },
  computed: {
    ...mapState("modOperacion", ["listas"]),
    ...mapState("modHidrocarburos", [
      "programaciones",
      "nominacionesList",
      "loading",
    ]),

    filteredRows() {
      return this.programacionesTabla.filter((row) => {
        const fecha_inicial = row.fecha_inicial;
        const fecha_final = row.fecha_final;
        const estado = row.nEstado;
        const created_at = row.created_at;
        const searchTerm = this.filter.toLowerCase();

        return (
          fecha_inicial.includes(searchTerm) ||
          fecha_final.includes(searchTerm) ||
          estado.includes(searchTerm) ||
          created_at.includes(searchTerm)
        );
      });
    },
  },
  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modHidrocarburos", [
      "actGetProgramaciones",
      "actGetNominacionesList",
      "actDeleteProgramacion",
    ]),

    highlightMatches(texto) {
      var text = String(texto);
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },

    filtrarTabla(page = 1) {
      var data = [];
      (this.filtros = {
        id: this.id ? this.id : null,
        nominacion_id: this.nominacion.id ? this.nominacion.id : null,
        fecha_inicial: this.fecha_inicial ? this.fecha_inicial : null,
        fecha_final: this.fecha_final ? this.fecha_final : null,
        estado: this.estado ? this.estado : null,
      }),
        (data[0] = page);
      data[1] = this.filtros;

      this.programacionesTabla = [];
      this.actGetProgramaciones(data).then(() => {
        this.programacionesTabla = this.programaciones.data;
      });
    },

    /*editProgramacion(id){
      this.$router.push({
        name: "/Hidrocarburos/ProgramacionEdit",
        params: { id: id }
      });
    },*/

    delProgramacion(id) {
      this.$swal({
        title: "Esta seguro de eliminar la Programación: " + id + "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          this.actDeleteProgramacion(id).then(() => {
            var data = [];
            data[0] = 1;
            data[1] = this.filtros;
            this.actGetProgramaciones(data).then(() => {
              this.programacionesTabla = this.programaciones.data;
            });
            this.$swal({
              icon: "success",
              title: "Se eliminó la Programación exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },
};
</script>

<!-- <style lang="scss">
[v-cloak] {
  display: none;
}
.edit {
  display: none;
}
.editing .edit {
  display: block;
}
.editing .view {
  display: none;
}
</style>
 -->